














































































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { IResetPassword } from '@/types/types';
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('min', {
  ...min,
  message: 'Field cannot have less than 6 characters',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ResetPassword extends mixins(WidgetMixins) {
  bg: string = require('@/assets/images/app-background.svg');
  show: boolean = false;

  code: string = '';
  username: string = '';
  newPassword: string = '';
  confirmPassword: string = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldState: any = {
    username: '',
    newPassword: false,
    confirmPassword: false,
  };

  completeAccount(): void {
    let payload: IResetPassword & { username: string } = {
      token: decodeURIComponent(this.$route.query.token as string),
      username: this.username,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword,
    };

    this.$store.dispatch('users/completeAccountSetUp', payload);
  }

  changeState(state: string): void {
    this.$set(this.fieldState, state, !this.fieldState[state]);
  }
}
